.topbar {
  padding: 1rem;
  z-index: 15;
  background-color: #001423;
}

.title {
  color: white;
  font-family: 'Crimson Text', serif;
}
