.img-wrapper img {
  width: 100%;
}

.profile-img-wrapper {
  display: flex;
  align-items: center;
}

.banner {
  width: 80%;
  margin: 0 auto;
}

.presentation {
  color: #7ed07e;
  font-family: 'Crimson Text', serif;
  font-size: 37px;
  line-height: 3rem;
}

.description {
  flex: 1;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: 300;
  margin-right: 2rem;
  line-height: 2.5rem;
}

.description-wrapper {
  display: flex;
  margin-top: 5rem;
  flex-wrap: wrap-reverse;
}

.profile-img-wrapper img {
  max-width: 300px;
}

@media (min-width: 586px) {
  .presentation {
    font-size: 67px;
    line-height: 6rem;
  }
}
