.contact {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 2.5rem;
  width: 80%;
  margin: 2rem auto;
}

.email {
  color: #f9690e;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

.email:hover {
  transform: scale(1.1);
}
